import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Product from "../components/Product";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import Loader from "../components/Loader.jsx";
import Message from "../components/Message.jsx";
import { useParams } from "react-router-dom";
import Paginate from "../components/Paginate";
import { Link } from "react-router-dom";
import TopProductCarousel from '../components/TopProductCarousel';


const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  return (
    <>
    { !keyword ? <TopProductCarousel /> : (<Link to='/' className='btn btn-light mb-4'>Go Back</Link>)}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h1 style={{ display: "flex", justifyContent: "center" }}>
            Latest Products
          </h1>
          <Row>
            {data.products.map((product) => {
              return (
                <Col key={product._id}  sm={12} md={6} lg={4} xl={3}>
                  
                    <Product product={product} />
                </Col>
              );
            })}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ""}
          />
        </>
      )}
    </>
  );
};

export default HomeScreen;
